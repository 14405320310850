import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Let's talk about `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Abstract_syntax_tree"
      }}>{`syntax trees`}</a>{`. In static analysis, a common operation is to take a character string (e.g. "x = 1") and transform it into well structured data:`}</p>
    <CaptionedImage filename="pyparsing-trees-1.png" alt="Simple syntax tree" caption="" mdxType="CaptionedImage" />
    <p>{`Let's try to do this in python. We'll use `}<a parentName="p" {...{
        "href": "http://pyparsing.wikispaces.com/"
      }}>{`Pyparsing`}</a>{` as our tokenization tool - you can use pyparsing's rules to build a grammar, from which you can get back a list of token data.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` pyparsing `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` alphas, Word, Literal, nums`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar = Literal(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"var"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + Word(alphas) + Literal(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"="`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + Word(nums)`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`>>> print(grammar.parseString("var x = 1"))`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`['var', 'x', '=', '1']`}</span></code></pre>
    <p>{`This is great, but what if we need a tree instead of an array of tokens?`}</p>
    <p>{`For example, let's say we have a language that describes a tree, where each node in the tree can have a number. Each node is represented by a pair of parentheses, and inside is the value followed by the child nodes:`}</p>
    <CaptionedImage filename="pyparsing-trees-2.png" alt="Simple syntax tree" caption="" mdxType="CaptionedImage" />
    <p>{`How could we write a parser for this? It differs in a couple of ways from the first example:`}</p>
    <ol>
      <li parentName="ol">{`It's inherently recursive, since after the value in each node, zero or more child nodes can follow:`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": "nohighlight"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`node := (value[, node, node, node, ...])`}</span></code></pre></li>
      <li parentName="ol">{`If we parse the string as a list, we won't be able to preserve the tree structure.`}</li>
    </ol>
    <h3 {...{
      "id": "recursive-grammars-with-pyparsing"
    }}>{`Recursive grammars with pyparsing`}</h3>
    <p>{`Pyparsing supports recursive grammars using a grammar component called `}<inlineCode parentName="p">{`Forward`}</inlineCode>{`. To define a recursive grammar, you create a `}<inlineCode parentName="p">{`pyparsing.Forward()`}</inlineCode>{`, then use the shift left operator to set its content:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar = pyparsing.Forward()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar << pyparsing.Suppress(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"("`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + pyparsing.Word(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"0123456789"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + pyparsing.ZeroOrMore(grammar) + pyparsing.Suppress(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`")"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`query = `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"(1 (2 (3)) (4 (5 (6) (7) (8)))"`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`>>> print(grammar.parseString(query))`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`['5', '6', '7', '9', '9', '5', '6', '7', '8', '9', '7', '8', '9']`}</span></code></pre>
    <p>{`This parses the grammar, but we still don't have the tree structure.`}</p>
    <h3 {...{
      "id": "parse-actions"
    }}>{`Parse actions`}</h3>
    <p>{`Pyparsing allows a "parse action" to be defined:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`def`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`action`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`location`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`tokens`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`):`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tokens`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar.setParseAction(action)`}</span></span></code></pre>
    <p>{`The parse action is a function which will be applied every time a parse element generates output. It takes three arguments:`}</p>
    <ol>
      <li parentName="ol">{`The full input string being parsed.`}</li>
      <li parentName="ol">{`The location of the parse element as an index into the full string.`}</li>
      <li parentName="ol">{`The list generated tokens, as strings.`}</li>
    </ol>
    <p>{`The value returned from the parse action function will replace the string tokens, and the default implementation does nothing with the tokens and just returns them the way they are. The parse action is our chance to hook into pyparsing and output structured data instead of strings.`}</p>
    <p>{`As an example:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` pyparsing`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Item`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`def`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`__init__`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`value`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`):`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.value = value`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`def`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`__repr__`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`):`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`{Item: %s}`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` % `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.value`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`def`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`transform`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`location`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`tokens`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`):`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [Item(token) `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`for`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` token `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`in`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tokens]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar = Literal(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"var"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + Word(alphas) + Literal(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"="`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + Word(nums)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar.setParseAction(transform)`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`>>> print(grammar.parseString("var x = 1"))`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`[{Item: var}, {Item: x}, {Item: =}, {Item: 1}]`}</span></code></pre>
    <p>{`Now we have items instead of strings.`}</p>
    <h3 {...{
      "id": "building-a-tree"
    }}>{`Building a tree`}</h3>
    <p>{`Let's apply this to the tree grammar to build a tree. We'll define a simple node class:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` collections `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` namedtuple`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`Node = namedtuple(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Node"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, [`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"value"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"children"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`])`}</span></span></code></pre>
    <p>{`And define the recursive grammar:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` pyparsing `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Forward, Suppress, Word, ZeroOrMore`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar = Forward()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar << Suppress(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"("`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + Word(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"0123456789"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) + ZeroOrMore(grammar) + Suppress(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`")"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`def`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`parseAction`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`location`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`tokens`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`):`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Node(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`value`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=tokens[`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`], `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`children`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=tokens[`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`:])`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`grammar.setParseAction(parseAction)`}</span></span></code></pre>
    <p>{`And a way to print it out and see if it worked:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "python"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`def`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`pprint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`tab`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`""`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`):`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`   `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` tab + `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`u`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"┗━ "`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` + `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`str`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(node.value)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`   `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`for`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` child `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`in`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` node.children:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      pprint(child, tab + `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"    "`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`>>> pprint(grammar.parseString("(1 (2 (3)) (4 (5 (6) (7) (8))))"))`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`┗━ 1`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    ┗━ 2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`        ┗━ 3`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    ┗━ 4`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`        ┗━ 5`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`            ┗━ 6`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`            ┗━ 7`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`            ┗━ 8`}</span></code></pre>
    <p>{`Not bad: a simple parser in 9 lines of code.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      